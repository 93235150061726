@use '@/styles/utils/mixins.scss' as *;

.ctaBannerWrapper {
  margin-top: 7.5rem;
  @include tab-des() {
    margin-top: 5.125rem;
  }
  .ctaBannerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7.875rem;
    @include tab-des() {
      flex-direction: column-reverse;
      gap: 6.25rem;
    }
  }

  .ctaBannerContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    width: 100%;
    @include tab-des() {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .ctaBannerHeadingContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    @include tab-des() {
      text-align: center;
    }
  }

  .offeringText {
    color: $primary;
    text-align: left;
    width: 100%;
    display: block;
    position: relative;
    min-height: 4.875rem;
    text-wrap: auto;

    @include tab-des() {
      text-align: center;
      min-height: 2.625rem;
    }

    .offeringTextItem {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      white-space: normal;
      transition: opacity 0.5s ease forwards;

      &.active {
        opacity: 1;
      }
    }
  }
  .description {
    text-align: left;

    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;
    @include tab-des() {
      text-align: center;
    }
  }
  .leftContentSection {
    grid-column: span 2;
    @include tab-des() {
      grid-column: span 1;
    }
  }
  .rightContentSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding-top: 6.25rem;
    padding-bottom: 1.875rem;
    @include tab-des() {
      padding-top: 0rem;
      padding-bottom: 0rem;
      align-items: center;
    }
  }
  .bannerImageContainer {
    position: relative;
    overflow: hidden;
    border-radius: 1.25rem;
    width: 100%;
    height: 26.875rem;
    @include tab-des() {
      margin: 0 -1.875rem;
      width: calc(100% + 3.75rem);
    }
    @include mob() {
      height: 26rem;
    }
    .bannerImageWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: all 0.1s ease;
    }

    .bannerImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
